
/*========== Buttons Scss ==========*/

.btn *,
a[role="button"],
button[type="button"] {
  outline: none;
  text-transform: capitalize;
  font-weight: $font-weight-normal;
}


/*========== Badge Scss ==========*/

.badge:not(.badge-xs) {
  padding: 5px 15px 4px;
  color: $white;
  &:hover,
  &:focus,
  &.active {
    color: $white;
  }
}
