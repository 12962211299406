.admin-page-content {
  padding: 16px;
  width: 100%;
  overflow: hidden;
  display: flex;
  height: 100%;
  .ButtonGroup {
    display: flex;
    flex-direction: row;
    .buttonSidebar {
      height: 10%;
      background-color: #c0d5ee;
      color: #25486d;
      min-height: 50px;
      padding: 10px;
      &:active {
        transform: scale(1);
      }
    }
  }
}
input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.custom-tabs {
  width: 100%;
}
.tabs-wrapper {
  background-color: white;
}
.custom-tab-indicator {
  display: none;
}

.custom-tab {
  background-color: $primary;

  height: 48px;
  color: $primary-dark !important;

  font-family: Roboto;

  margin: 0 4px;
  button {
    background: transparent !important;
  }
}
.custom-tab.active {
  background-color: white;
  box-shadow: 0 -5px 4px 1px #bbb;
  font-weight: bold !important;
}
.admin-side-bar {
  color: $primary-dark;
  background-color: $primary-light;
  min-width: 350px;
  max-width: 350px;
  header {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    background-color: $primary;
    h6 {
      margin: 0;
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      min-width: 225px;
    }
    button {
      border: none;
      background-color: #ffffff;
      outline: none;
      color: $primary-dark;
      font-weight: bold;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      margin: 0 6px;
      cursor: pointer;
      line-height: 1px;
    }
  }
  ul {
    list-style: none;
    .link {
      cursor: pointer;
      width: 100%;
      color: #25486d;
      min-height: 60px;
      padding: 23px 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-decoration: none;
      .company-li {
        &:hover {
          .button-delete-company {
            opacity: 1;
            background: red;
            height: 25px;
            color: white;
          }
        }
        .button-delete-company {
          background: red;
          height: 25px;
          color: white;
          opacity: 0;
        }
      }
    }
    .link:hover {
      background-color: white;
      box-shadow: 0px 2px 5px #cccc;
    }
    .active {
      background-color: white;
      box-shadow: 0px 2px 5px #cccc;
    }
    li {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      .delete-icon {
        font-size: 16px;
      }
    }
  }
}

.container-company-avatar {
  max-width: 100%;
  display: grid;
  grid-template-columns: 120px auto auto 80px;
  gap: 10px;
  align-items: center;
  grid-template-areas: "avatar-container name-container domain-container switch-container";
  height: 100px;
  min-height: 100px;
  overflow-x: auto;
  overflow-y: visible;
  padding-inline: 15px;
  .avatar-container {
    position: inherit;
    overflow-y: visible;
    .company-avatar {
      width: 50px;
      height: 50px;
      clip-path: circle(50px at center);
      cursor: pointer;
      z-index: 2;
      label {
        z-index: 10;
        text-align: center;
      }
    }
    .avatar-peding {
      height: 60px;
      background: lightgray;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.8;
      text-align: center;
    }
  }
  .company-name-container {
    display: flex;
    flex-direction: column;
  }
  .company-domain-container {
    display: flex;
    gap: 5px;
    .domain-title {
      display: flex;
      flex-direction: column;
    }
  }
  input {
    border: none;
    font-size: 1.3em;
    font-weight: 500;
    // width: auto;
    color: #25486d;
  }
  input:focus {
    border: solid 1px;
  }
  .editable-label {
    margin-left: 12px;
    min-height: 30px;
    min-width: 100px;
    max-width: 150px;
    display: flex;
    align-items: center;
    input {
      min-height: 30px;
      max-width: 200px;
    }
    label {
      margin-bottom: 0;
    }
  }
  .see-able {
    background-color: #efefef;
  }
  label {
    margin: 0;
  }
}
.avatar-container {
  position: relative;
  overflow: hidden;
  display: flex;
  .avatar-hover {
    width: 40px;
    height: 40px;
    background-color: #000;
    z-index: 5;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    transition: all 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .avatar-hover-userform {
    width: 80px;
    height: 80px;
    background-color: #000;
    z-index: 5;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    transition: all 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .opaque {
    opacity: 0.2;
  }
  .company-avatar {
    width: 50px;
    height: 50px;
    clip-path: circle(50px at center);
    cursor: pointer;
    z-index: 2;
    label {
      z-index: 10;
    }
  }
  .user-avatar {
    width: 80px;
    height: 80px;
    clip-path: circle(80px at center);
    cursor: pointer;
    z-index: 2;
    label {
      z-index: 10;
    }
  }
}
.button span:before {
  content: "+";
  padding-right: 5px;
}

.avatar-hover {
  width: 50px;
  height: 50px;
  background-color: #000;
  z-index: 5;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.opaque {
  opacity: 0.2;
}

.row-hover-over {
  :hover {
    background-color: $secondary;
    cursor: pointer;
  }
  .noHover {
    cursor: unset;
    span {
      cursor: unset;
    }
    .MuiAvatar-root {
      cursor: unset;
      img {
        cursor: unset;
      }
    }
    td {
      font-size: 0.875rem;
    }
  }
}

.clases-icon-root {
  min-width: 0px;
  width: 35px;
  right: 0 !important;
  padding: 0px !important;
}
