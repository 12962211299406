/* Custom Progress */

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.4;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.8;
  }
}

.notification-badge {
  background: #e02020 !important;
  color: #fff !important;
  border: 1.5px solid #e5edf7 !important;
  padding: 0 2px;
}

.small-badge {
  background: #e02020 !important;
  color: #fff !important;
  border: 1.5px solid #e5edf7 !important;
  font-size: 11px;
  height: 18px;
  min-width: 18;
}

.badge-notification-number {
  padding: 2px 3px;
  font-size: 11px;
  right: 2px;
  font-weight: 400;
  min-width: 16.6px;
  min-height: 0px;
  height: auto;
  width: auto;
  letter-spacing: 0.5px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #e02020;
    border-radius: 100%;
    z-index: -1;
    animation: pulse 2s infinite;
    will-change: transform;
  }
}
