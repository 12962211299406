.listbox {
  .option {
    font-size: 14px;
    padding: 4px 8px;
    background: white;
  }
  .option[data-focus="true"] {
    background-color: #e6f0f9 !important;
  }
  .option[aria-selected="true"] {
    background-color: rgba(0, 0, 0, 0);
  }
}


.autocomplete-tag-root {
  width: 90%;
  min-height: 35px;
  height: 35px;
  .inputRoot {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    border-radius: 2px;
    fieldset {
      border: 1px solid #d2dfec;
      :hover {
        border: 1px solid #d2dfec;
      }
    }
  }
  .input {
    padding: 0px !important;
    height: 35px !important;
    font-size: 14px;
    color: #25486d;
  }
  .endAdorment {
    width: 24px;
    height: 24px;
    .clearIndicator {
      width: 24px;
      min-width: 24px;
      height: 24px;
    }
  }
  .textField-root {
    height: 35px;
    min-height: 35px;
    margin-top: 0;
  }
}

