
*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

.notification-list-section {
  list-style-type: disc;
  margin-left: 20px;
  li {
    margin: 6px 8px 6px 0px;
  }
}
