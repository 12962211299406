mark {
  padding: 0 !important;
  background-color: #ffff00;
  font-weight: bold;
}




.general-filter {
  :hover {
    background-color: #e0e3e7;
  }
  .MuiFilledInput-root {
    transition: none;
  }
  .MuiInputLabel-shrink {
    transform: translate(0, 24px) scale(1);
    transform-origin: top left;
  }
  .customer-filter-label {
    label {
      margin: 0;
      transform: translate(0, 24px) scale(1) !important;
    }
  }
}


.tooltip-filter {
  display: flex;
  flex-wrap: wrap;
}
