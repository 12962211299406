button {
  border: none;
  margin: 4px;
  height: 35px;
  border-radius: 5px;
  transition: all 0.3s;
  color: white;
  background-color: $button-danger-bg;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
button:hover {
  cursor: pointer;
}

button:disabled {
  opacity: 0.4;
  cursor: default;
}

button:active {
  transform: scale(0.9);
}

button:focus {
  outline: none;
  border-color: #25486d;
}

.button-success {
  background-color: $button-success-bg;
}
.button-success-login {
  background-color: #25486d;
  padding: 10px 35px;
  width: 255px;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 400;
}
.button-cancel {
  background-color: $button-cancel-bg;
}
.button-danger {
  background-color: $button-danger-bg;
}
.button-create {
  background-color: transparent;
  color: $primary-dark;
  border: none;
  font-size: 12px;
  height: auto;
  width: auto;
  font-weight: bold;
  padding: 0;
  text-transform: uppercase;
}
.button-create::before {
  content: "+";
  padding-right: 5px;
}
.button-purchase-order {
  background-color: $primary-light;
  color: $primary-dark;
  font-weight: bold;
}
.button-white-button {
  color: #25486d;
  cursor: pointer;
  padding: 10px 20px 10px 20px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 4px;
  background-color: white;
}
.button-table {
  min-width: 80px;
  height: 30px;
  background-color: $primary-light;
  color: $primary-dark;
  border-radius: 0;
  max-width: 80px;
  font-size: 10px;
  font-weight: bold;
}
