


.sales-order-title {
  font-size: 14px;
  display: flex;
  background-color: white;
  padding: 17.5px;
  justify-content: space-between;
}

section {
  display: flex;
  justify-content: space-between;
}

.header-container {
  position: sticky;
  background: white;
  top: 0px;
  z-index: 2;
  button {
    background-color: $primary-dark;
    color: white;
  }
}


input {
  background: transparent;
  border: 1px solid #ccc;
  padding-left: 2px;
  color: #464d68;
  font-family: "Roboto", sans-serif !important;
}
select {
  background: transparent;
  border: 1px solid #ccc;
  padding-left: 2px;
  color: #464d68;
  font-family: "Roboto", sans-serif !important;
}




.MuiCircularProgress-static {
  position: relative;
  top: 2px;
  background: #f2f2f2;
  border-radius: 100%;
  &:before {
    content: "";
    width: 4px;
    height: 4px;
    position: absolute;
    background: white;
    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
