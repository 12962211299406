/*============ Tables Style ===========*/

table {
  thead,
  tbody {
    tr {
      &[class*="bg-"] {
        td,
        th {
          color: $white !important;
        }
      }
    }
  }
  thead {
    background-color: $table-head-bg;
    tr {
      th {
        font-size: $table-tbody-font-size;
        font-weight: 500;
        color: $table-head-color;
        border-width: 1px !important;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: $table-tbody-font-size;
        font-weight: $table-tbody-font-weight;
      }
    }
  }
}

.sticky-header {
  th {
    position: sticky;
    top: 0;
    background-color: $primary-light;
    color: $primary-dark;
    font-weight: bold;
    z-index: 1;
  }
}
